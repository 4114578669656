import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/components/about/About.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/components/card/Card.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/components/framer/WhileInView.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/components/goDown/goDown.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/page.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/components/noscript/NoScript.module.scss");
